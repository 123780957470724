import * as React from 'react';
import {FaInstagram, FaLinkedinIn, FaTiktok} from "react-icons/fa";

export default function IntroFooter() {
    return (
        <footer id="footer1" className="page-section text-center pt-100-b-80-cont">
            <div className="container">


                <div className="footer-soc-a">
                    <a href="https://www.tiktok.com/@mc.careers" target="_blank">
                        <FaTiktok/>
                    </a>
                    <a href="https://www.instagram.com/mc_careers/" target="_blank">
                        <FaInstagram/>
                    </a>
                    <a href="https://www.linkedin.com/company/meaningfulcareers/" target="_blank">
                        <FaLinkedinIn/>
                    </a>
                </div>


                <div className="footer-copy">
                    &copy; Meaningful Careers {new Date().getFullYear()}
                </div>

            </div>
        </footer>
    );
}

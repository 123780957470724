export const JobStatus = Object.freeze({
    NEW: "Applied",
    PENDING_REVIEW: "Pending Review",
    UNDER_REVIEW: "Under Review",
    SELECTED_FOR_INTERVIEW: "Selected for Interview",
    INTERVIEW_SCHEDULED: "Interview Scheduled",
    CURRENTLY_INTERVIEWING: "Currently Interviewing",
    INTERVIEW_COMPLETE: "Interview Complete",
    OFFER_REJECTED: "Offer Rejected",
    OFFERED: "Offered",
    HIRED: "Hired",
    NOT_SELECTED: "Not Selected",
    WITHDRAWN: "Withdrawn",
});

export const AllowedJobStatusActions = {
    [JobStatus.PENDING_REVIEW]: [JobStatus.UNDER_REVIEW, JobStatus.NOT_SELECTED],
    [JobStatus.UNDER_REVIEW]: [JobStatus.SELECTED_FOR_INTERVIEW, JobStatus.NOT_SELECTED],
    [JobStatus.SELECTED_FOR_INTERVIEW]: [JobStatus.INTERVIEW_SCHEDULED, JobStatus.NOT_SELECTED],
    [JobStatus.INTERVIEW_SCHEDULED]: [JobStatus.CURRENTLY_INTERVIEWING, JobStatus.NOT_SELECTED],
    [JobStatus.CURRENTLY_INTERVIEWING]: [JobStatus.INTERVIEW_COMPLETE, JobStatus.NOT_SELECTED],
    [JobStatus.INTERVIEW_COMPLETE]: [JobStatus.OFFERED, JobStatus.NOT_SELECTED],
    [JobStatus.OFFERED]: [JobStatus.HIRED, JobStatus.OFFER_REJECTED],
};

export const CareerType = Object.freeze({
    ARCHIVED: {name: 'archived', idx: 0},
    PAUSED: {name: 'paused', idx: 1},
    APPROVED: {name: 'approved', idx: 2},
    PENDING: {name: 'pending', idx: 3},
});

export const SearchCategory = Object.freeze({
    CAREERS: 'careers',
    APPLICATIONS: 'applications',
    PROFILES: 'profiles',
    COMPANIES: 'companies',
});

export const finalJobStatuses = [JobStatus.NOT_SELECTED, JobStatus.OFFER_REJECTED, JobStatus.WITHDRAWN, JobStatus.HIRED];

export const RoutePath = Object.freeze({
    HOME: "/home",
    CANDIDATE_HOME: "/candidates/home",
    EMPLOYER_HOME: "/employers/home",
    SIGN_IN: "/sign-in",
    SIGN_UP: "/sign-up",
    EMPLOYER_SIGN_UP: "/employers/sign-up",
    EMPLOYER_CONFIRMATION: "/employers/confirmation",
    RESET_PASSWORD: "/reset-password",
    CAREER_VIEW: (id = ':id') => `/careers/${id}`,
    CAREER_VIEW_ALL: "/careers",
    CAREER_VIEW_ALL_PENDING_APPROVAL: "/careers/pending",
    CAREER_VIEW_ALL_PAUSED: "/careers/paused",
    CAREER_VIEW_ALL_ARCHIVED: "/careers/archived",
    CAREER_VIEW_ALL_STARRED: "/careers/starred",
    CAREER_ADD: "/careers/add",
    CAREER_EDIT: (id = ':id') => `/careers/edit/${id}`,
    CAREER_DELETE: "/careers/delete/:id",
    APPLICATION_VIEW: "/applications/:id",
    APPLICATION_VIEW_ALL: "/applications",
    APPLICATION_CREATE: (id = ':careerId') => `/applications/create/${id}`,
    APPLICATION_FOR_CAREER: (id = ':careerId') => `/applications/careers/${id}`,
    APPLICATION_USER_DETAILS: (applicationId = ':applicationId', userId = ':userId') => `/applications/${applicationId}/users/${userId}`,
    APPLICATION_ADD: "/applications/add",
    APPLICATION_EDIT: "/applications/edit/:id",
    APPLICATION_DELETE: "/applications/delete/:id",
    PROFILE_VIEW: (id = ':id') => `/profiles/${id}`,
    PROFILE_VIEW_ALL: "/profiles",
    PROFILE_EDIT: "/profile/edit",
    COMPANY_VIEW_ALL: "/companies",
    COMPANY_ADD: "/companies/add",
    COMPANY_EDIT: (id = ':id') => `/companies/edit/${id}`,
});

export const RouteRestriction = Object.freeze({
    ADMIN_ONLY: "ADMIN_ONLY",
    USER_ONLY: "USER_ONLY",
    ANYONE_AUTHENTICATED: "ANYONE_AUTHENTICATED",
    ANYONE_UNAUTHENTICATED: "ANYONE_UNAUTHENTICATED",
});
import React, {useEffect, useRef, useState} from "react";

const candidateTestimonials = [
    {
        text: "As a recent PSW graduate, MC made my job search easy. The platform is user-friendly, with clear job descriptions—no more guessing games. I found a role that perfectly matches my skills and goals. I highly recommend MC to anyone looking for their next career opportunity!",
        author: "Michael B.",
        position: "LTC PSW",
    },
    {
        text: "Thanks to their personalized support, I landed my dream role as a UX Designer at a company that truly values creativity and user experience. The process was seamless, and their guidance made all the difference!",
        author: "Sarah T.",
        position: "UX Designer",
    },
    {
        text: "I was stuck trying to figure out my next move, but MC's advisors made everything so much easier. They introduced me to a Data Engineer role that’s a perfect fit, and their guidance gave me the confidence to ace the interview. I’m so grateful for their support!",
        author: "Priya M.",
        position: "Data Engineer",
    },
    {
        text: "Finding the right Software Developer role felt impossible until I connected with MC. Their platform and team made the process easy and stress-free. I’m finally in a job that matches my goals, and I couldn’t be happier!",
        author: "Alex R.",
        position: "Senior Software Developer",
    },
    {
        text: "The opportunities were clear, and the application process was seamless. Thanks to their help, I secured a position that perfectly aligns with my career goals in healthcare!",
        author: "Jessica F.",
        position: "Medical Assistant",
    },
    {
        text: "MC transformed my job search experience. Instead of endless applications and uncertainty, I was matched with an industrial engineering role that truly fits my expertise and career path. Their team provided the right support at every step, making the process efficient and stress-free.",
        author: "David C.",
        position: "Industrial Engineer",
    },
];

const employerTestimonials = [
    {
        text: "MC made our hiring process a breeze! Their efficient and supportive approach helped us find the right people quickly, saving us valuable time and letting us focus on our projects.",
        author: "James R.",
        position: "Executive Director,  Industrial Development",
    },
    {
        text: "Working with MC was a refreshing experience! They provided seamless and friendly service, making it much easier to find qualified candidates. The team genuinely cares about matching the right people to the right roles, and their support has been invaluable to our hiring process.",
        author: "Linda J.",
        position: "Human Resources Manager, Outpatient Care Services",
    },
    {
        text: "MC made hiring effortless from start to finish. They quickly connected us with the right people, just in time for our busiest season.",
        author: "Scott T.",
        position: "Residential & Commercial Contractor",
    },
    {
        text: "As a rapidly growing startup, finding the right people quickly is everything. MC made it happen, connecting us with talented candidates who aligned with our mission and values - ready to hit the ground running. Their process was simple, and their support was invaluable.",
        author: "Sam L.",
        position: "Co-Founder, EdTech",
    },
    {
        text: "Finding dependable and compassionate caregivers can be challenging, but MC really delivered. They helped us hire skilled professionals who genuinely care about their work, and it’s made a huge difference for our clients.",
        author: "Karen H.",
        position: "HR Director, Home Health Care Services",
    },
    {
        text: "For critical hires, MC is our go-to. Their focus isn’t just on filling positions—it’s on helping us build a stronger, better team. This partnership approach has been absolutely invaluable.",
        author: "Erin C.",
        position: "COO, Fintech",
    },
];


const TestimonialSlider = ({isEmployerPage = false}) => {
    const testimonials = isEmployerPage ? employerTestimonials : candidateTestimonials;
    const [index, setIndex] = useState(0);
    const containerRef = useRef(null);
    const [maxHeight, setMaxHeight] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        }, 4000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const updateMaxHeight = () => {
            if (containerRef.current) {
                const heights = Array.from(containerRef.current.children).map((child) =>
                    child.getBoundingClientRect().height
                );
                setMaxHeight(Math.max(...heights));
            }
        };

        updateMaxHeight();

        window.addEventListener("resize", updateMaxHeight);

        return () => window.removeEventListener("resize", updateMaxHeight);
    }, []);

    return (
        <div className="mt-50 mb-50" style={{width: "90%", margin: "auto"}}>
                <div className="p-80-cont pt-40">
                    <div ref={containerRef} style={{minHeight: maxHeight || "auto", transition: "height 0.3s ease"}}>
                        {testimonials.map((testimonial, i) => (
                            <div key={i} style={{display: i === index ? "block" : "none"}}>
                                <div className="relative">
                                    <div className="row plr-50">
                                        <div className="col-md-8">
                                            <blockquote className="testimonial-2">
                                                <p>{testimonial.text}</p>
                                            </blockquote>
                                        </div>

                                        <div className="col-md-3 col-md-offset-1">
                                            <div className="ts-author-cont">
                                                <div className="ts-author-info">
                                                    <div className="ts-name">
                                                        <span className="bold">{testimonial.author}</span>
                                                    </div>
                                                    <div className="ts-type">{testimonial.position}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Dots Navigation */}
                    <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
                        {testimonials.map((_, i) => (
                            <div
                                key={i}
                                onClick={() => setIndex(i)} // Clicking a dot changes the slide
                                style={{
                                    width: "12px",
                                    height: "12px",
                                    margin: "0 5px",
                                    borderRadius: "50%",
                                    backgroundColor: i === index ? "#333" : "#bbb", // Active dot is darker
                                    cursor: "pointer",
                                    transition: "background-color 0.3s ease",
                                }}
                            />
                        ))}
                    </div>
                </div>
        </div>
    );
};

export default TestimonialSlider;

import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {logSentryException} from "../../utils/utils.js";
import {MAX_LOCATION_COUNT} from "../../utils/constants.js";

export const LocationDropdown = ({onChange, value, error, helperText, allowMultipleOptions = false}) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const {openSnackbar} = useSnackbar();

    useEffect(() => {
        if (inputValue === '') {
            setOptions([]);
            return;
        }

        const fetchLocations = async () => {
            try {
                const autocompleteService = new window.google.maps.places.AutocompleteService();

                autocompleteService.getPlacePredictions({
                    input: inputValue,
                    types: ['(cities)'],
                    componentRestrictions: {country: ['us', 'ca']}
                }, (predictions, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        setOptions(predictions.map((prediction) => prediction.description));
                    }
                });
            } catch (e) {
                openSnackbar('Failed to fetch locations.');
                logSentryException(e);
            }
        };

        fetchLocations();
    }, [inputValue]);

    return (
        <Autocomplete
            multiple={allowMultipleOptions}
            value={value}
            onChange={onChange}
            noOptionsText='Start typing to search for a location'
            options={options}
            filterSelectedOptions={allowMultipleOptions}
            inputValue={inputValue}
            onInputChange={(e, newInputValue) => {
                if (allowMultipleOptions && value.length >= MAX_LOCATION_COUNT) {
                    openSnackbar(`You cannot enter more than ${MAX_LOCATION_COUNT} locations.`, 'warning');
                    setInputValue('');
                    return;
                }

                if (!allowMultipleOptions && newInputValue.trim().length <= 0) {
                    onChange(e, null);
                }

                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params}
                           label="Location"
                           sx={{marginTop: 2, marginBottom: 1}}
                           error={error}
                           helperText={helperText}
                           required/>
            )}
        />
    );
}

import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import {useHistory, useLocation} from 'react-router-dom';
import {useAuth} from "../../contexts/AuthContext";
import {Logo} from "./Logo";
import {RoutePath} from "../../utils/enums";
import {Drawer, List, ListItem, ListItemButton} from "@mui/material";
import {Close, MenuOutlined} from "@mui/icons-material";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {logSentryException} from "../../utils/utils.js";
import Typography from "@mui/material/Typography";

const introPages = [
    {name: 'For Candidates', path: RoutePath.CANDIDATE_HOME},
    {name: 'For Employers', path: RoutePath.EMPLOYER_HOME},
];

export const MobileMenu = () => {
    const [userAnchorElement, setUserAnchorElement] = useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const {logout, authenticatedUser, isAdmin} = useAuth();
    const history = useHistory();
    const pages = introPages;
    const {isAuthenticated} = useAuth();
    const {openSnackbar} = useSnackbar();
    const location = useLocation();

    const closeUserMenu = () => setUserAnchorElement(null);

    const handleLogoutUser = async () => {
        try {
            await logout();
            history.push(RoutePath.CAREER_VIEW_ALL);
            closeUserMenu();
            openSnackbar('User logged out successfully.')
        } catch (e) {
            openSnackbar(e.message, 'error');
            logSentryException(e);
        }
    };

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box sx={{
            display: 'block',
            width: 'fit-content',
            position: 'absolute',
            top: '25px',
            right: 0,
            zIndex: 999,
            '@media (min-width: 1025px)': {
                display: 'none',
            },
        }}>
            <IconButton
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                aria-label="open drawer"
                edge="start"
                onClick={() => setIsDrawerOpen(true)}
                sx={{
                    mr: 2,
                    color: location.pathname === RoutePath.HOME ? 'black' : 'white',
                    '& .MuiSvgIcon-root': {
                        fontSize: '2rem',
                    }
                }}
            >
                <MenuOutlined/>
            </IconButton>
            <Drawer
                variant="temporary"
                open={isDrawerOpen}
                anchor='top'
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    '@media (min-width: 1025px)': {
                        display: 'none',
                    },
                    '@media (max-width: 1024px)': {
                        display: 'flex',
                    },
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', height: '100vh'},
                }}
            >
                <Box>
                    <Close sx={{float: 'right', mt: 4, mx: 3}} onClick={() => setIsDrawerOpen(false)}/>
                    <div style={{clear: 'both'}}></div>
                    <Logo styles={{mx: 'auto', mb: 2}}/>
                    <List>
                        {pages.map((menuItem) =>
                            <ListItem key={menuItem.name} disablePadding>
                                <ListItemButton
                                    sx={{textAlign: 'center'}}
                                    onClick={() => {
                                        history.push(menuItem.path);
                                        setIsDrawerOpen(false);
                                    }}>
                                    <Typography sx={{textTransform: 'uppercase', fontSize: '15px !important', margin: 'auto', padding: '5px'}}>{menuItem.name}</Typography>
                                </ListItemButton>
                            </ListItem>
                        )}
                        <ListItem key='auth-menu' disablePadding>
                            <ListItemButton
                                sx={{textAlign: 'center'}}
                                onClick={async () => {
                                    if (isAuthenticated()) {
                                        await handleLogoutUser();
                                        setIsDrawerOpen(false);
                                    } else {
                                        history.push(RoutePath.SIGN_IN);
                                        setIsDrawerOpen(false);
                                    }
                                }}>
                                <Typography sx={{textTransform: 'uppercase', fontSize: '15px !important', margin: 'auto', padding: '5px'}}>{isAuthenticated() ? 'Log Out' : 'Sign In'}</Typography>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </Box>
    )
};

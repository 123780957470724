import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useAuth} from "../../contexts/AuthContext";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {ApplicationManager} from "../../managers/ApplicationManager";
import {CareerManager} from "../../managers/CareerManager";
import {CareerType, RoutePath} from "../../utils/enums";
import {ResumeSelector} from "../universal/ResumeSelector.js";
import {CoverLetterSelector} from "../universal/CoverLetterSelector.js";
import {UserManager} from "../../managers/UserManager";
import {CircularProgress, Divider, FormLabel, Radio, RadioGroup} from "@mui/material";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {
    applicationSubmittedAdminNotificationEmailTemplate,
    applicationSubmittedCandidateEmailTemplate,
    getPublicCompanyData,
    logSentryException,
    sendGenericEmail
} from "../../utils/utils.js";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

export const ApplicationForm = () => {
    const [loading, setLoading] = useState(false)
    const {careerId} = useParams();
    const {authenticatedUser, setAuthenticatedUser} = useAuth()
    const {openSnackbar} = useSnackbar();
    const [career, setCareer] = useState({})
    const [vocationalPartner, setVocationalPartner] = useState(null)
    const [selectedResume, setSelectedResume] = useState(null);
    const [selectedCoverLetter, setSelectedCoverLetter] = useState(null);
    const [isVocationalPartnerStudent, setIsVocationalPartnerStudent] = useState(null); // "yes", "no", null
    const history = useHistory();

    useEffect(() => {
        setUp()
            .then()
            .catch((e) => {
                openSnackbar('Something went wrong. Please refresh the page.', 'error');
                logSentryException(e);
            });
    }, []);

    const setUp = async () => {
        if (!authenticatedUser.isProfileComplete) {
            history.push(RoutePath.PROFILE_EDIT);
            openSnackbar('Please complete your profile before applying.', 'warning');
        } else if (authenticatedUser.appliedCareers.includes(careerId)) {
            history.push(RoutePath.CAREER_VIEW_ALL);
            openSnackbar('You have applied for the selected career already.', 'warning');
        } else {
            const retrievedCareer = await CareerManager.getById(careerId);
            setCareer(retrievedCareer);

            if (retrievedCareer && retrievedCareer.status !== CareerType.APPROVED.name) {
                history.push(RoutePath.CAREER_VIEW_ALL);
                openSnackbar('You can apply only to open job postings.', 'warning');
            }

            if (retrievedCareer.vocationalPartnerId !== null) {
                const retrievedVocationalPartner = await getPublicCompanyData({companyId: retrievedCareer.vocationalPartnerId});
                setVocationalPartner(retrievedVocationalPartner.data);
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        let formValidationErrors = {}

        try {
            setLoading(true)

            formValidationErrors = validateForm();

            if (Object.values(formValidationErrors).indexOf(true) >= 0) {
                throw Error;
            }

            if (career.vocationalPartnerId !== null && isVocationalPartnerStudent == null) {
                throw Error("Please select a yes/no option.");
            }

            const application = {
                userId: authenticatedUser.id,
                careerId: career.id,
                resumeLink: selectedResume.url,
                coverLetterLink: selectedCoverLetter ? selectedCoverLetter.url : null,
                isVocationalPartnerStudent: career.vocationalPartnerId !== null ? isVocationalPartnerStudent === "yes" : null,
            }
            const updatedUser = {
                ...authenticatedUser,
                appliedCareers: [...authenticatedUser.appliedCareers, careerId],
                starredCareers: authenticatedUser.starredCareers.filter(val => val !== career.id),
            }

            await ApplicationManager.create(application);
            await UserManager.update(updatedUser);
            setAuthenticatedUser(updatedUser);

            history.push(RoutePath.APPLICATION_VIEW_ALL)
            openSnackbar('Application submitted successfully.');

            await sendEmails();
        } catch (e) {
            const errorMessage = e.message ? e.message : 'Please select a resume.';
            openSnackbar(errorMessage, 'error');
            logSentryException(e);
        }

        setLoading(false);
    };

    const sendEmails = async () => {
        try {
            await sendGenericEmail({
                to: process.env.REACT_APP_MC_ADMIN_EMAIL,
                subject: `New application: ${career.title} - ${authenticatedUser.firstName} ${authenticatedUser.lastName}`,
                content: applicationSubmittedAdminNotificationEmailTemplate(authenticatedUser, career)
            });

            await sendGenericEmail({
                to: authenticatedUser.email,
                subject: `You have successfully applied for '${career.title}' role!`,
                content: applicationSubmittedCandidateEmailTemplate(authenticatedUser, career)
            });
        } catch (e) {
            openSnackbar('Notification email failed to send.', 'error');
            logSentryException(e);
        }
    }

    const validateForm = () => {
        const errors = {};

        errors.resume = selectedResume === null;

        return errors;
    }

    return (
        <Container maxWidth="xs" style={{marginTop: "30px"}}>
            <CssBaseline/>
            <Typography variant="h3" align="center" color="text.primary">
                Application
            </Typography>
            <Typography
                variant="h6"
                align="center"
                color="text.primary"
                gutterBottom
            >
                for "{career.title}" career
            </Typography>

            <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                <ResumeSelector selectedResume={selectedResume}
                                setSelectedResume={setSelectedResume}/>

                <Divider sx={{my: 2}}/>

                <CoverLetterSelector selectedCoverLetter={selectedCoverLetter}
                                     setSelectedCoverLetter={setSelectedCoverLetter}/>

                {
                    vocationalPartner !== null &&
                    <FormControl sx={{mt: 3}}>
                        <FormLabel id="isVocationalPartnerStudent">Are you associated with "{vocationalPartner.name}"? This does not affect your application. It is strictly for reporting purposes.</FormLabel>
                        <RadioGroup
                            aria-labelledby="isVocationalPartnerStudent"
                            name="isVocationalPartnerStudent"
                            value={isVocationalPartnerStudent}
                            onChange={(e) => setIsVocationalPartnerStudent(e.target.value)}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                }

                {
                    loading ?
                        <Button startIcon={<CircularProgress color='primary' size={20}/>}
                                variant="contained"
                                disabled
                                fullWidth
                                sx={{mt: 3, mb: 2}}>
                            Submitting...
                        </Button> :
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            disabled={loading}
                        >
                            Submit
                        </Button>
                }
            </Box>
        </Container>
    )
}
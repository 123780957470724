import * as React from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {adminPalette} from "../utils/theme.js";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import {RoutePath} from "../utils/enums.js";
import {MobileMenu} from "./nav/MobileMenu.js";

export const EmployerConfirmationPage = () => {
    return (
        <div id="wrap" className="boxed">
            <div className="grey-bg">
                <div style={{
                    backgroundColor: '#8B0E3A'
                }}>
                    <header id="nav" className={`header header-1 black-header`}>
                        <div className="header-wrapper maroon">
                            <div className="container-m-30 clearfix">
                                <div className="logo-row">
                                    <div className="logo-container-2">
                                        <div className="logo-2">
                                            <Link to={RoutePath.HOME} className="clearfix">
                                                <img src="/images/white-long-logo.png" className="logo-img" alt="Logo"/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <MobileMenu/>

                            <div className="main-menu-container">

                                <div className="container-m-30 clearfix">


                                    <div id="main-menu">
                                        <div className="navbar navbar-default" role="navigation">


                                            <nav className="right-1024" style={{
                                                padding: '10px 0'
                                            }}>
                                                <ul id="nav-onepage" className="nav navbar-nav">
                                                    <li>
                                                        <Link to={RoutePath.CANDIDATE_HOME}>
                                                            <div className="main-menu-title">FOR CANDIDATES</div>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={RoutePath.EMPLOYER_HOME}>
                                                            <div className="main-menu-title">FOR EMPLOYEES</div>
                                                        </Link>
                                                    </li>
                                                    <li style={{
                                                        paddingLeft: '3rem'
                                                    }}>
                                                        <Link to={RoutePath.SIGN_IN}>
                                                            <div className="main-menu-title">SIGN IN</div>
                                                        </Link>
                                                    </li>
                                                </ul>

                                            </nav>

                                        </div>
                                    </div>

                                </div>


                            </div>


                        </div>


                    </header>
                    <Grid container justifyContent="center" sx={{
                        height: '100vh',
                        overflowX: 'hidden',
                        overflow: 'hidden',
                        m: 0,
                        p: 0
                    }}>
                        <Grid item xs={12}
                              container
                              direction="column"
                              justifyContent="center"
                              sx={{
                                  width: '100%',
                                  backgroundColor: adminPalette.main,
                                  position: 'relative',
                              }}>
                            <Box sx={{
                                height: {xs: '50%', md: '40%'},
                                width: {xs: '100%', md: '60%'},
                                mx: 'auto',
                                zIndex: '0',
                                background: "url('/images/background-box-girl.png') no-repeat center center/contain"
                            }}/>
                            <Box sx={{mx: 'auto', width: '70%', mt: 2, zIndex: '10'}}>
                                <Typography variant="h4"
                                            sx={{
                                                fontWeight: 'bold',
                                                color: 'white',
                                                textAlign: 'center',
                                                fontSize: '3.3rem'
                                            }}
                                            gutterBottom>
                                    Thank you for filling out the form!
                                </Typography>
                                <Typography paragraph
                                            sx={{
                                                color: 'white',
                                                fontWeight: 100,
                                                textAlign: 'center',
                                                fontSize: '1.7rem'
                                            }}>
                                    We'll be in touch shortly.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </div>
            </div>
            );
            };
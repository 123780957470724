import * as React from 'react';
import {RoutePath} from "../../../utils/enums.js";
import {Link} from "react-router-dom";
import {MobileMenu} from "../../nav/MobileMenu.js";

export const HomePage = () => {
    return (
        <div id="wrap" className="boxed">
            <div className="grey-bg">
                <div style={{
                    backgroundColor: 'white'
                }}>
                    <header id="nav" className={`header header-1 black-header`}>
                        <div className="header-wrapper maroon">
                            <div className="container-m-30 clearfix">
                                <div className="logo-row">
                                    <div className="logo-container-2">
                                        <div className="logo-2">
                                            <Link to={RoutePath.HOME} className="clearfix">
                                                <img src="/images/black-long-logo.png" className="logo-img" alt="Logo"/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <MobileMenu/>

                            <div className="main-menu-container">

                                <div className="container-m-30 clearfix">


                                    <div id="main-menu">
                                        <div className="navbar navbar-default" role="navigation">


                                            <nav className="right-1024" style={{
                                                padding: '10px 0',
                                            }}>
                                                <ul id="nav-onepage" className="nav navbar-nav black-text">
                                                    <li style={{
                                                        paddingLeft: '3rem',
                                                    }}>
                                                        <Link to={RoutePath.SIGN_IN}>
                                                            <div className="main-menu-title" style={{color: "black"}}>SIGN IN</div>
                                                        </Link>
                                                    </li>
                                                </ul>

                                            </nav>

                                        </div>
                                    </div>

                                </div>


                            </div>


                        </div>
                    </header>

                    <div className="sm-img-bg-fullscr parallax-section"
                         style={{
                             background: 'white',
                             marginBottom: '3rem',
                         }} data-stellar-background-ratio="0.5">
                        <div className="container sm-content-cont text-center"
                             style={{height: `${window.innerHeight}px`}}>
                            <div className="sm-cont-middle">
                                <div className="opacity-scroll2">

                                    <div className="row" style={{
                                        width: '100%',
                                        margin: 'auto'
                                    }}>
                                        <div className="fes-img-main" style={{
                                            height: '320px'
                                        }}></div>
                                    </div>

                                    <div className="light-72-wide sm-mb-15 sm-mt-20">
                                        MEANINGFUL <span className="bold">CAREERS</span>
                                    </div>

                                    <div className="norm-16-wide sm-mb-50">
                                        At MC, we connect people with meaningful careers and businesses with the talent
                                        they
                                        need to grow.
                                    </div>

                                    <div className="center-0-478">
                                        <Link to={RoutePath.CANDIDATE_HOME} className="button medium thin hover-opacity"
                                              style={{
                                                  background: '#5C0E7B',
                                                  color: 'white',
                                                  border: '2px solid #5C0E7B'
                                              }}>CANDIDATES</Link>
                                        <Link to={RoutePath.EMPLOYER_HOME}
                                              className="button medium thin ml-20 hover-opacity"
                                              style={{
                                                  background: '#8B0E3A',
                                                  color: 'white',
                                                  border: '2px solid #8B0E3A'
                                              }}>EMPLOYERS</Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
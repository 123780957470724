import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {RoutePath} from "../utils/enums.js";

const DynamicStyles = () => {
    const location = useLocation();
    const allowedPaths = [RoutePath.HOME, RoutePath.EMPLOYER_HOME, RoutePath.CANDIDATE_HOME, RoutePath.EMPLOYER_CONFIRMATION];

    useEffect(() => {
        const stylesheets = [
            "bootstrap-css",
            "google-fonts",
            "icons-fonts",
            "theme-style",
            "animate-css",
        ];

        if (allowedPaths.includes(location.pathname)) {
            stylesheets.forEach((id) => {
                const link = document.getElementById(id);
                if (link instanceof HTMLLinkElement) {
                    link.removeAttribute("disabled");
                }
            });
        } else {
            stylesheets.forEach((id) => {
                const link = document.getElementById(id);
                if (link instanceof HTMLLinkElement) {
                    link.setAttribute("disabled", "true");
                }
            });
        }
    }, [location.pathname]);

    return null;
};

export default DynamicStyles;

export const jobCategories = [
    'Supply Chain & Logistics',
    'Construction Engineering',
    'Quality Management',
    'Civil Engineering',
    'Mechanical Engineering',
    'Administration',
    'Nursing',
    'Housekeeping',
    'Home Health Services',
    'Technician',
    'Skill Trades',
    'General Labor',
    'Customer Success',
    'Business Development',
    'Software Development',
    'Project Management',
    'Operations',
];
export const jobSettings = ['Remote', 'In-person', 'Hybrid'];
export const experienceLevels = ['Entry-Level', 'Mid-Level', 'Experienced Mid-Level', 'Senior-Level', 'Team Lead', 'Manager', 'Director', 'Executive', 'C-Level (C-suite)']
export const values = ['Innovation', 'Customer-Centric', 'Collaboration', 'Transparency', 'Adaptability', 'Diversity', 'Integrity', 'Quality', 'Agility', 'Accountability', 'Autonomy'];
export const workTypes = ['Permanent Full-Time', 'Permanent Part-Time', 'Contract Full-Time', 'Contract Part-Time', 'Temporary/Seasonal', 'Internship/Co-op', 'Freelance/Independent Contracting']
export const MAX_STARRED_CAREER_COUNT = 20;
export const RESULTS_PER_PAGE = 6;
export const MAX_LOCATION_COUNT = 10;
export const DEFAULT_SALARY_RANGE = [80, 90];
export const DEFAULT_HOURLY_RANGE = [35, 40];
import React, {useEffect, useRef, useState} from "react";

const companyLogos = [
    '/images/logos/1.png',
    '/images/logos/2.png',
    '/images/logos/3.png',
    '/images/logos/4.png',
    '/images/logos/5.png',
    '/images/logos/6.png',
    '/images/logos/7.png',
    '/images/logos/8.png',
    '/images/logos/9.png',
    '/images/logos/10.png',
    '/images/logos/11.png',
    '/images/logos/12.png',
    '/images/logos/13.png',
    '/images/logos/14.png',
    '/images/logos/15.png',
    '/images/logos/16.png',
    '/images/logos/17.png',
    '/images/logos/18.png',
]

const unLogos = [
    '/images/un_logos/SDGMC.png',
    '/images/un_logos/1.png',
    '/images/un_logos/2.png',
    '/images/un_logos/3.png',
    '/images/un_logos/4.png',
    '/images/un_logos/5.png',
    '/images/un_logos/6.png',
    '/images/un_logos/7.png',
    '/images/un_logos/8.png',
]

const LogoCarousel = ({isCompanyRelated}) => {
    const logos = isCompanyRelated ? companyLogos : unLogos;
    const duplicatedLogos = [...logos, ...logos];

    const containerRef = useRef(null);
    const [offset, setOffset] = useState(0);
    const [numVisible, setNumVisible] = useState(window.innerWidth < 500 ? 2 : (window.innerWidth >= 1000 ? 5 : 3));
    const logoSize = 140;
    const gap = 20;
    const totalSlideWidth = logoSize + gap;
    const resetThreshold = logos.length * totalSlideWidth;

    useEffect(() => {
        const handleResize = () => {
            setNumVisible(window.innerWidth < 500 ? 2 : (window.innerWidth >= 1000 ? 5 : 3));
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setOffset((prevOffset) => prevOffset + totalSlideWidth);
        }, 3000);

        return () => clearInterval(interval);
    }, [totalSlideWidth]);

    useEffect(() => {
        // reset offset to avoid abrupt jump-back
        if (offset >= resetThreshold) {
            setTimeout(() => {
                setOffset(0);
            }, 500);
        }
    }, [offset, resetThreshold]);

    return (
        <div
            className="row mb-30"
            style={{
                width: `${numVisible * totalSlideWidth - gap}px`,
                overflow: "hidden",
                margin: "auto",
                position: "relative",
            }}
        >
            <div
                ref={containerRef}
                className="logo-carousel"
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: `${gap}px`,
                    width: `${duplicatedLogos.length * totalSlideWidth}px`,
                    transform: `translateX(-${offset}px)`,
                    transition: offset === 0 ? "none" : "transform 0.5s ease-in-out",
                }}
            >
                {duplicatedLogos.map((logo, i) => (
                    <div key={i} className="text-center" style={{flexShrink: 0}}>
                        <img
                            src={logo}
                            alt={`logo-${i}`}
                            style={{width: `${logoSize}px`, height: `${logoSize}px`, objectFit: "contain"}}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LogoCarousel;

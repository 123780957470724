import * as React from 'react';
import {useEffect, useState} from 'react';
import IntroFooter from "../IntroFooter.js";
import {RoutePath} from "../../../utils/enums.js";
import {Link} from "react-router-dom";
import {MobileMenu} from "../../nav/MobileMenu.js";
import TestimonialCarousel from "../TestimonialCarousel.js";
import LogoCarousel from "../LogoCarousel.js";

export const CandidateHomePage = () => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 0 && window.innerWidth >= 1025);
        };

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div id="wrap" className="boxed">
            <div className="grey-bg">
                <div style={{
                    backgroundColor: '#5C0E7B'
                }}>

                    <header id="nav" className={`${scrolled ? 'affix' : ''} header header-1 black-header`}>
                        <div className="header-wrapper">
                            <div className="container-m-30 clearfix">
                                <div className="logo-row">
                                    <div className="logo-container-2">
                                        <div className="logo-2">
                                            <Link to={RoutePath.HOME} className="clearfix">
                                                <img src="/images/white-long-logo.png" className="logo-img" alt="Logo"/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <MobileMenu/>

                            <div className="main-menu-container">

                                <div className="container-m-30 clearfix">


                                    <div id="main-menu">
                                        <div className="navbar navbar-default" role="navigation">


                                            <nav className="right-1024" style={{
                                                padding: '10px 0'
                                            }}>
                                                <ul id="nav-onepage" className="nav navbar-nav">
                                                    <li className="current">
                                                        <Link to={RoutePath.CANDIDATE_HOME}>
                                                            <div className="main-menu-title">FOR CANDIDATES</div>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={RoutePath.EMPLOYER_HOME}>
                                                            <div className="main-menu-title">FOR EMPLOYEES</div>
                                                        </Link>
                                                    </li>
                                                    <li style={{
                                                        paddingLeft: '3rem'
                                                    }}>
                                                        <Link to={RoutePath.SIGN_IN}>
                                                            <div className="main-menu-title">SIGN IN</div>
                                                        </Link>
                                                    </li>
                                                </ul>

                                            </nav>

                                        </div>
                                    </div>

                                </div>


                            </div>


                        </div>


                    </header>


                    <div className="sm-img-bg-fullscr parallax-section"
                         style={{
                             backgroundColor: '#5C0E7B'
                         }} data-stellar-background-ratio="0.5">
                        <div className="container sm-content-cont text-center"
                             style={{height: `${window.innerHeight}px`}}>
                            <div className="sm-cont-middle">


                                <div className="opacity-scroll2">


                                    <div className="font-white light-72-wide sm-mb-15 sm-mt-20">
                                        CAREER SUCCESS<br/><span className="bold">YOUR WAY</span>
                                    </div>


                                    <div className="center-0-478">
                                        <Link className="button medium thin hover-dark-purple tp-button white mt-20"
                                              to={RoutePath.SIGN_UP}>GET STARTED</Link>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="local-scroll-cont font-white">
                            <a href="#about" className="scroll-down smooth-scroll">
                                <div className="icon icon-arrows-down"></div>
                            </a>
                        </div>
                    </div>

                </div>


                <div id="about" className="page-section p-110-cont">
                    <div className="container">
                        <p style={{
                            marginBottom: "24px",
                            color: "#000000",
                            fontFamily: "'Lato',Arial,Helvetica,sans-serif",
                            fontSize: "21px",
                            lineHeight: "32px",
                            fontWeight: "100",
                            fontStyle: "normal",
                            textAlign: 'center'
                        }}>
                            Tired of sending countless job applications without any replies?
                            <br/>
                            <br/>
                            At MC, we’re here to
                            help you find meaningful opportunities that match your goals. With us, your next career
                            move will be an exciting journey, not a hassle.
                        </p>
                    </div>
                </div>

                <div className="page-section">
                    <div className="container-fluid">
                        <div className="row">

                            <div className="col-md-6 wow fadeInLeft">
                                <div className="equal-height" style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-evenly',
                                    width: 'fit-content',
                                    margin: 'auto'
                                }}>
                                    <div style={{width: 'fit-content'}}>
                                        <div className="title-fs-45">
                                            Real Posts
                                            <br/>
                                            <span className="bold">No Ghosting</span>
                                        </div>
                                        <div className="line-3-70"></div>
                                    </div>
                                    <div style={{width: 'fit-content'}}>
                                        <div className="title-fs-45">
                                            Your Career Journey<br/>
                                            <span className="bold">Your ATS</span>
                                        </div>
                                        <div className="line-3-70"></div>
                                    </div>
                                    <div style={{width: 'fit-content'}}>
                                        <div className="title-fs-45">
                                            Reliable Support<br/>
                                            <span className="bold">Always</span>
                                        </div>
                                        <div className="line-3-70"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="row">
                                    <div className="candidate-desc-img equal-height"></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <TestimonialCarousel/>

                <LogoCarousel isCompanyRelated={true}/>
                <LogoCarousel isCompanyRelated={false}/>

                <hr className="mt-0 mb-0" style={{
                    border: '.5px solid grey'
                }}/>

                <IntroFooter/>

            </div>

        </div>
    )
};
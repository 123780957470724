import CardContent from "@mui/material/CardContent";
import {ApplicationStatusStepper} from "./ApplicationStatusStepper";
import Card from "@mui/material/Card";
import React, {forwardRef} from "react";
import {Divider} from "@mui/material";
import {useHistory} from "react-router-dom";
import {JobStatus, RoutePath} from "../../utils/enums";
import {ApplicationCardContent} from "./ApplicationCardContent.js";
import {adminPalette} from "../../utils/theme.js";

export const ApplicationWithApplicantDetailsCard = forwardRef(({application}, ref) => {
    const history = useHistory();
    const applicant = application.user;
    const currStatus = application.statusHistory[application.statusHistory.length - 1].status;

    return applicant ? (
        <Card ref={ref}
              sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  background: currStatus === JobStatus.PENDING_REVIEW ? adminPalette.main + '20' : '#FFF',
                  transition: 'background 0.2s ease-in-out',
                  '&:hover': {
                      background: currStatus === JobStatus.PENDING_REVIEW ? adminPalette.main + '35' : '#F5F5F5',
                  },
              }} onClick={() => history.push(RoutePath.APPLICATION_USER_DETAILS(application.id, applicant.id))}>
            <CardContent sx={{flexGrow: 1}}>
                <ApplicationStatusStepper steps={application.statusHistory} isShortVersion={true}/>
                <Divider sx={{my: 1}}/>
                <ApplicationCardContent applicant={applicant} isVocationalPartnerStudent={application.isVocationalPartnerStudent}/>
            </CardContent>
        </Card>
    ) : null
})
export class Company {
    static collectionName = 'companies';

    constructor(data) {
        this.id = data.id ? data.id : '';
        this.name = data.name ? data.name : '';
        this.websiteUrl = data.websiteUrl ? data.websiteUrl : '';
        this.shortDescription = data.shortDescription ? data.shortDescription : '';
        this.pocFullName = data.pocFullName ? data.pocFullName : '';
        this.pocEmail = data.pocEmail ? data.pocEmail : '';
        this.createdAt = data.createdAt ? data.createdAt : null;
        this.deletedAt = data.deletedAt ? data.deletedAt : null;
        this.isVocationalPartner = data.isVocationalPartner ? data.isVocationalPartner : false;
    }
}
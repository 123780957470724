import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {CareerManager} from "../../managers/CareerManager";
import {
    experienceLevels,
    jobCategories,
    jobSettings,
    MAX_LOCATION_COUNT,
    values,
    workTypes
} from "../../utils/constants";
import {Autocomplete, CircularProgress, Dialog, DialogContent, DialogTitle} from "@mui/material";
import {CareerType, RoutePath} from "../../utils/enums";
import {useAuth} from "../../contexts/AuthContext";
import {CompanyManager} from "../../managers/CompanyManager";
import {LocationDropdown} from "../universal/LocationDropdown.js";
import {CustomAutocomplete} from "../universal/CustomAutocomplete.js";
import {generateCareer, getPublicCompanyData, isEmpty, logSentryException} from "../../utils/utils";
import {CustomDropdown} from "../universal/CustomDropdown.js";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {Career} from "../../entities/Career.js";
import {AutoAwesome, Close} from "@mui/icons-material";
import {LoadingIndicator} from "../universal/LoadingIndicator.js";
import SalarySelector from "../universal/salary/SalarySelector.js";
import IconButton from "@mui/material/IconButton";

const CareerForm = () => {
    const history = useHistory();
    const {isAdmin, authenticatedUser} = useAuth();
    const {id} = useParams(); // todo: rename to careerId
    const {openSnackbar} = useSnackbar();
    const [companyIdQueryParam, setCompanyIdQueryParam] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [generationPrompt, setGenerationPrompt] = useState('');
    const [errors, setErrors] = useState({})
    const [isFormSubmitting, setIsFormSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isDescriptionGenerating, setIsDescriptionGenerating] = useState(false)
    const [companyDropdownSelectedValue, setCompanyDropdownSelectedValue] = useState(null)
    const [vocationalPartnerDropdownSelectedValue, setVocationalPartnerDropdownSelectedValue] = useState(null)
    const [companyDropdownOptions, setCompanyDropdownOptions] = useState([])
    const [vocationalPartnerDropdownOptions, setVocationalPartnerDropdownOptions] = useState([])
    const [career, setCareer] = useState(new Career({status: CareerType.PENDING.name}))

    useEffect(() => {
        setUp()
            .then()
            .catch((e) => {
                history.push(RoutePath.CAREER_VIEW_ALL);
                openSnackbar(e.message, 'error')
                logSentryException(e);
            })
            .finally(() => setIsLoading(false));
    }, []);

    const setUp = async () => {
        const companyId = window.location.search.split('?companyId=')[1];
        const company = isAdmin() ? await CompanyManager.getById(companyId) : await getPublicCompanyData({companyId});
        let retrievedCareer = null;
        let isEditMode = false;
        setCompanyIdQueryParam(companyId);

        if (!isAdmin() && !id && !company) {
            history.push(RoutePath.CAREER_VIEW_ALL);
        }

        if (id) {
            retrievedCareer = await CareerManager.getById(id);
            setCareer(retrievedCareer);
            isEditMode = true;
            setIsEdit(true);
        }

        if (isAdmin()) {
            const companies = await CompanyManager.getAll();
            const companyOptions = companies.data.map(company => ({id: company.id, title: company.name}))
            const vocationalPartnerOptions = companies.data.filter(company => company.isVocationalPartner).map(company => ({id: company.id, title: company.name}))
            setCompanyDropdownOptions(companyOptions);
            setVocationalPartnerDropdownOptions(vocationalPartnerOptions);

            if (isEditMode && retrievedCareer) {
                const careerSelectedOption = companyOptions.filter(o => o.id === retrievedCareer.companyId)
                const vocationalPartnerSelectedOption = vocationalPartnerOptions.filter(o => o.id === retrievedCareer.vocationalPartnerId)
                setCompanyDropdownSelectedValue(careerSelectedOption.length ? {
                    id: retrievedCareer.companyId,
                    title: careerSelectedOption[0].title
                } : null);
                setVocationalPartnerDropdownSelectedValue(vocationalPartnerSelectedOption.length ? {
                    id: retrievedCareer.vocationalPartnerId,
                    title: vocationalPartnerSelectedOption[0].title
                } : null);
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        let formValidationErrors = {}

        try {
            setErrors({})
            setIsFormSubmitting(true);

            formValidationErrors = validateForm();

            if (Object.values(formValidationErrors).indexOf(true) >= 0) {
                throw Error;
            }
            console.log('career form', career)

            isEdit ?
                await CareerManager.update(career) :
                await CareerManager.create({...career, companyId: companyIdQueryParam || career.companyId});


            isAdmin() ? history.goBack() : history.push(RoutePath.CAREER_VIEW_ALL);
            openSnackbar('Career saved successfully');
        } catch (e) {
            const errorMessage = e.message ? e.message : 'Please check the form for errors.';
            setErrors(formValidationErrors);
            openSnackbar(errorMessage, 'error');
            logSentryException(e);
        } finally {
            setIsFormSubmitting(false);
        }
    };

    const generateJobDescription = async () => {
        setIsDescriptionGenerating(true);

        try {
            if (!generationPrompt || generationPrompt.trim() === '') {
                openSnackbar('A prompt is required.', 'error');
                return;
            }

            const result = await generateCareer({query: generationPrompt});
            const generatedCareer = new Career({...result.data, status: CareerType.PENDING.name});
            setCareer({...generatedCareer, companyId: career.companyId});
            setIsDialogOpen(false);
        } catch (e) {
            openSnackbar('Career couldn\'t be generated. Please try again with a different prompt.', 'error');
            logSentryException(e);
        } finally {
            setIsDescriptionGenerating(false);
        }
    }

    const validateForm = () => {
        const errors = {};

        const salary = career.salary;
        const hourlyRate = career.hourlyRate;
        const isNotValidSalaryRange = salary !== null && (salary.length !== 2 || salary[0] > salary[1]);
        const isNotValidHourlyRateRange = hourlyRate !== null && (hourlyRate.length !== 2 || hourlyRate[0] > hourlyRate[1]);

        errors.companyId = isEmpty(career.companyId ? career.companyId : '') && isEmpty(companyIdQueryParam ? companyIdQueryParam : '');
        errors.vocationalPartnerId = career.companyId === career.vocationalPartnerId && career.vocationalPartnerId !== null;
        errors.title = isEmpty(career.title);
        errors.category = isEmpty(career.category);
        errors.shortDescription = isEmpty(career.shortDescription);
        errors.descriptionOfResponsibilities = isEmpty(career.descriptionOfResponsibilities);
        errors.mustHaveQualifications = isEmpty(career.mustHaveQualifications);
        errors.niceToHaveQualifications = isEmpty(career.niceToHaveQualifications);
        errors.benefits = isEmpty(career.benefits, {ignore_whitespace: true});
        errors.values = career.values.length <= 0;
        errors.interviewSteps = isEmpty(career.interviewSteps);
        errors.salary = (salary === null && hourlyRate === null) || isNotValidSalaryRange || isNotValidHourlyRateRange;
        errors.teamStructure = isEmpty(career.teamStructure);
        errors.experienceLevel = isEmpty(career.experienceLevel);
        errors.workSetting = isEmpty(career.workSetting);
        errors.workType = isEmpty(career.workType);
        errors.location = career.location.length <= 0 || career.location.length > MAX_LOCATION_COUNT;

        return errors;
    }

    return (
        <Container maxWidth="xs" style={{marginTop: "30px"}}>
            <CssBaseline/>
            <LoadingIndicator isLoading={isLoading}>
                <Typography
                    component="h3"
                    variant="h4"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    {isEdit ? 'Edit Career' : 'Add Career'}
                </Typography>

                {
                    !isEdit &&
                    <Button variant="contained"
                            onClick={() => setIsDialogOpen(true)}
                            sx={{my: 2, borderRadius: 50}}
                            fullWidth>
                        <AutoAwesome sx={{
                            verticalAlign: 'middle',
                            fontSize: '1rem',
                            color: 'white',
                            mr: .5,
                            textTransform: 'capitalize'
                        }}/>
                        Generate Job Description
                    </Button>
                }

                <Box component="form" onSubmit={handleSubmit} noValidate>
                    {
                        (!companyIdQueryParam && isAdmin()) &&
                        <Autocomplete
                            id="companyId"
                            options={companyDropdownOptions}
                            getOptionLabel={(option) => option.title ? option.title : ''}
                            value={companyDropdownSelectedValue}
                            onChange={(event, option) => {
                                setCompanyDropdownSelectedValue(option)
                                setCareer({...career, companyId: option ? option.id : ''});
                            }}
                            renderInput={(params) => (
                                <TextField {...params}
                                           label="Company"
                                           error={errors.companyId}
                                           sx={{marginTop: 2, marginBottom: 1}}
                                           helperText={errors.companyId ? 'Company is required.' : null}
                                           required/>
                            )}
                        />
                    }
                    {
                        isAdmin() &&
                        <Autocomplete
                            id="vocationalParnerId"
                            options={vocationalPartnerDropdownOptions}
                            getOptionLabel={(option) => option.title ? option.title : ''}
                            value={vocationalPartnerDropdownSelectedValue}
                            onChange={(event, option) => {
                                setVocationalPartnerDropdownSelectedValue(option)
                                setCareer({...career, vocationalPartnerId: option ? option.id : null});
                            }}
                            renderInput={(params) => (
                                <TextField {...params}
                                           error={errors.vocationalPartnerId}
                                           helperText={errors.vocationalPartnerId ? 'Vocational Partner must be different from Company.' : null}
                                           label="Vocational Parner"
                                           sx={{marginTop: 2, marginBottom: 1}}/>
                            )}
                        />
                    }
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="title"
                        label="Title"
                        name="title"
                        autoComplete="Title"
                        error={errors.title}
                        onChange={(e) => setCareer({...career, title: e.target.value})}
                        value={career.title ? career.title : ''}
                        helperText={errors.title ? 'Title is required.' : null}
                    />
                    <TextField
                        id="shortDescription"
                        label="Short Description"
                        multiline
                        fullWidth
                        required
                        rows={4}
                        name="shortDescription"
                        autoComplete="Short Description"
                        error={errors.shortDescription}
                        onChange={(e) => setCareer({...career, shortDescription: e.target.value})}
                        value={career.shortDescription ? career.shortDescription : ''}
                        helperText={errors.shortDescription ? 'Short description is required.' : null}
                        sx={{mt: 2, mb: 1}}
                    />
                    <TextField
                        margin="normal"
                        multiline
                        fullWidth
                        required
                        rows={4}
                        id="descriptionOfResponsibilities"
                        label="Description of Responsibilities"
                        name="descriptionOfResponsibilities"
                        autoComplete="Description of Responsibilities"
                        error={errors.descriptionOfResponsibilities}
                        onChange={(e) => setCareer({...career, descriptionOfResponsibilities: e.target.value})}
                        value={career.descriptionOfResponsibilities ? career.descriptionOfResponsibilities : ''}
                        helperText={errors.descriptionOfResponsibilities ? 'Description of responsibilities is required.' : null}
                    />
                    <CustomDropdown label='Category'
                                    value={career.category ? career.category : ''}
                                    onChange={(e) => setCareer({...career, category: e.target.value})}
                                    error={errors.category}
                                    options={jobCategories}/>
                    <TextField
                        margin="normal"
                        multiline
                        fullWidth
                        required
                        rows={4}
                        id="mustHaveQualifications"
                        label="Must-Have Qualifications"
                        name="mustHaveQualifications"
                        autoComplete="Must-Have Qualifications"
                        error={errors.mustHaveQualifications}
                        onChange={(e) => setCareer({...career, mustHaveQualifications: e.target.value})}
                        value={career.mustHaveQualifications ? career.mustHaveQualifications : ''}
                        helperText={errors.mustHaveQualifications ? 'Must-have qualifications are required.' : null}
                    />
                    <TextField
                        margin="normal"
                        multiline
                        fullWidth
                        required
                        rows={4}
                        id="niceToHaveQualifications"
                        label="Nice-to-Have Qualifications"
                        name="niceToHaveQualifications"
                        autoComplete="Nice-to-Have Qualifications"
                        error={errors.niceToHaveQualifications}
                        onChange={(e) => setCareer({...career, niceToHaveQualifications: e.target.value})}
                        value={career.niceToHaveQualifications ? career.niceToHaveQualifications : ''}
                        helperText={errors.niceToHaveQualifications ? 'Nice-to-have qualifications are required.' : null}
                    />
                    <TextField
                        margin="normal"
                        multiline
                        fullWidth
                        required
                        rows={4}
                        id="benefits"
                        label="Benefits"
                        name="benefits"
                        autoComplete="Benefits"
                        error={errors.benefits}
                        onChange={(e) => setCareer({...career, benefits: e.target.value})}
                        value={career.benefits ? career.benefits : ''}
                        helperText={errors.benefits ? 'Benefits are required.' : null}
                    />

                    <CustomAutocomplete label='Values'
                                        selectedValues={career.values}
                                        onChange={(event, value) => setCareer({...career, values: value})}
                                        error={errors.values}
                                        options={values}/>

                    <TextField
                        margin="normal"
                        multiline
                        fullWidth
                        required
                        rows={4}
                        id="interviewSteps"
                        label="Interview Process"
                        name="interviewSteps"
                        autoComplete="Interview Process"
                        error={errors.interviewSteps}
                        onChange={(e) => setCareer({...career, interviewSteps: e.target.value})}
                        value={career.interviewSteps ? career.interviewSteps : ''}
                        helperText={errors.interviewSteps ? 'Interview steps are required.' : null}
                    />
                    <SalarySelector
                        salary={career.salary}
                        setSalary={(salary) => setCareer({...career, salary: salary, hourlyRate: null})}
                        hourlyRate={career.hourlyRate}
                        setHourlyRate={(hourlyRate) => setCareer({...career, hourlyRate: hourlyRate, salary: null})}
                        error={errors.salary}
                        hideError={() => setErrors(errors => ({...errors, salary: false}))}
                    />
                    <TextField
                        margin="normal"
                        multiline
                        fullWidth
                        required
                        rows={4}
                        id="teamStructure"
                        label="Team Structure"
                        name="teamStructure"
                        autoComplete="Team Structure"
                        error={errors.teamStructure}
                        onChange={(e) => setCareer({...career, teamStructure: e.target.value})}
                        value={career.teamStructure ? career.teamStructure : ''}
                        helperText={errors.teamStructure ? 'Team structure is required.' : null}
                    />
                    <CustomDropdown label='Experience Level'
                                    value={career.experienceLevel ? career.experienceLevel : ''}
                                    onChange={(e) => setCareer({
                                        ...career,
                                        experienceLevel: e.target.value
                                    })}
                                    error={errors.experienceLevel}
                                    options={experienceLevels}/>
                    <CustomDropdown label='Work Setting'
                                    value={career.workSetting ? career.workSetting : ''}
                                    onChange={(e) => setCareer({...career, workSetting: e.target.value})}
                                    error={errors.workSetting}
                                    options={jobSettings}/>
                    <CustomDropdown label='Work Type'
                                    value={career.workType ? career.workType : ''}
                                    onChange={(e) => setCareer({...career, workType: e.target.value})}
                                    error={errors.workType}
                                    options={workTypes}/>
                    <LocationDropdown error={errors.location}
                                      onChange={(_, options) => setCareer({...career, location: options})}
                                      value={career.location ? career.location : []}
                                      helperText={errors.location ? 'Location is required. Please select from 1 to 5 valid values from the dropdown.' : null}
                                      allowMultipleOptions={true}/>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                        disabled={isFormSubmitting}
                    >
                        {isEdit ? 'Update' : 'Save'}
                    </Button>
                </Box>

                <Dialog
                    open={isDialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    xs={{justifyContent: 'center'}}
                    onClose={() => setIsDialogOpen(false)}
                >
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center'}}>
                        Generate Job Description

                        <IconButton
                            aria-label="close"
                            onClick={() => setIsDialogOpen(false)}
                            sx={{position: 'absolute', right: 8, top: 8}}
                        >
                            <Close/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{textAlign: 'center'}}>
                        <TextField
                            id="search-input field"
                            placeholder="Briefly describe your job posting..."
                            variant="outlined"
                            sx={{mb: 2}}
                            InputProps={{
                                style: {borderRadius: 50},
                            }}
                            fullWidth
                            value={generationPrompt}
                            onInput={(e) => setGenerationPrompt(e.target.value)}
                            autoComplete="off"
                        />
                        {
                            isDescriptionGenerating ?
                                <Button startIcon={<CircularProgress color='primary' size={20}/>}
                                        variant="contained"
                                        disabled sx={{width: '200px'}}>Generating...</Button> :
                                <Button variant="contained" onClick={generateJobDescription}
                                        sx={{width: '200px'}}>
                                    <AutoAwesome sx={{fontSize: '1.1rem', color: 'white', mr: .5}}/>Generate</Button>
                        }
                    </DialogContent>
                </Dialog>
            </LoadingIndicator>
        </Container>
    )
}

export default CareerForm
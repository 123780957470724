import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {AuthProvider} from './contexts/AuthContext';
import {AppContent} from "./AppContent";
import {SnackbarProvider} from "./contexts/SnackbarContext.js";
import {QueryClient, QueryClientProvider} from "react-query";
import DynamicStyles from "./components/DynamicStyles.js";

export const App = () => {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <SnackbarProvider>
                    <AuthProvider>
                        <DynamicStyles/>
                        <AppContent/>
                    </AuthProvider>
                </SnackbarProvider>
            </Router>
        </QueryClientProvider>
    );
}

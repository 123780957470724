import {User} from "./User.js";

export class Application {
    static collectionName = 'applications';

    constructor(data) {
        this.id = data.id ? data.id : '';
        this.userId = data.userId ? data.userId : '';
        this.careerId = data.careerId ? data.careerId : '';
        this.adminNotes = data.adminNotes ? data.adminNotes : '';
        this.statusHistory = data.statusHistory ? data.statusHistory.map(sh => typeof sh === 'string' ? JSON.parse(sh) : sh) : [];
        this.currStatus = data.currStatus ? data.currStatus : false;
        this.isFinalized = data.isFinalized ? data.isFinalized : false;
        this.isVocationalPartnerStudent = data.isVocationalPartnerStudent === true || data.isVocationalPartnerStudent === false ? data.isVocationalPartnerStudent : null;
        this.isCompanyNotified = data.isCompanyNotified === true || data.isCompanyNotified === false ? data.isCompanyNotified : false;
        this.resumeLink = data.resumeLink ? data.resumeLink : null;
        this.coverLetterLink = data.coverLetterLink ? data.coverLetterLink : null;
        this.submittedAt = data.submittedAt ? data.submittedAt : null;
        this.user = data.user ? new User(JSON.parse(data.user)) : null;
        this.career = null;
    }
}